/* eslint-disable no-underscore-dangle */
import { action, computed, makeObservable, observable } from "mobx";
import { Question } from "../types/Question";

export default class QuestionModel {
  @observable correct_answer: string = "";

  @observable distractor_1: string = "";

  @observable distractor_2: string = "";

  @observable habit: number | undefined;

  @observable question: string = "";

  @observable explanation: string = "";

  @observable question_background: string = "";

  @observable evaluated: boolean = false;

  @observable language: string = "en";

  @observable editable: boolean = false;

  @observable newQuestion: boolean = false;

  constructor(question: Question) {
    makeObservable(this);
    this.correct_answer = question.correct_answer;
    this.distractor_1 = question.distractor_1;
    this.distractor_2 = question.distractor_2;
    this.question = question.question;
    this.question_background = question.question_background;
    this.habit = question.habit ? Number(question.habit) : undefined;
    this.evaluated = question.evaluated || false;
    this.language = question.language || "en";
    this.editable = question.editable || false;
    this.newQuestion = question.newQuestion || false;
  }

  @action
  setCurrentAnswer(newValue: string) {
    this.correct_answer = newValue;
  }

  @action
  setDistractor1(newValue: string) {
    this.distractor_1 = newValue;
  }

  @action
  setDistractor2(newValue: string) {
    this.distractor_2 = newValue;
  }

  @action
  setHabit(newValue: number | undefined) {
    this.habit = newValue;
  }

  @action
  setQuestion(newValue: string) {
    this.question = newValue;
  }

  @action
  setExplanation(newValue: string) {
    this.explanation = newValue;
  }

  @action
  setQuestionBackground(newValue: string) {
    this.question_background = newValue;
  }

  @action
  setEvaluated(newValue: boolean) {
    this.evaluated = newValue;
  }

  @action
  setLanguage(newValue: string) {
    this.language = newValue;
  }

  @action
  setEditable(newValue: boolean) {
    this.editable = newValue;
  }

  @action
  setNewQuestion(newValue: boolean) {
    this.newQuestion = newValue;
  }

  @action
  reset(question: QuestionModel) {
    this.correct_answer = question.correct_answer;
    this.distractor_1 = question.distractor_1;
    this.distractor_2 = question.distractor_2;
    this.editable = question.editable;
    this.evaluated = question.evaluated;
    this.explanation = question.explanation;
    this.habit = question.habit;
    this.language = question.language;
    this.question = question.question;
    this.question_background = question.question_background;
  }

  @computed
  get habitError(): boolean {
    return !this.habit || Number(this.habit) < 1 || Number(this.habit) > 8;
  }

  @computed
  get isCorrect(): boolean {
    return this.question !== '' && this.correct_answer !== '' && this.distractor_1 !== '' && this.distractor_2 !== '' && this.correct_answer !== ''
  }
}
