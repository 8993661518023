import axios, { AxiosRequestConfig } from "axios";

export async function get<T>(
  url: string,
  params?: URLSearchParams,
  config: AxiosRequestConfig = {},
) {
  params?.forEach((value, key) => {
    if (!value || value === "undefined") {
      params.delete(key);
    }
  });
  return axios.get<T>(process.env.REACT_APP_ENDPOINT_ROOTAPI + url, {
    params,
    ...config,
  });
}

export async function put<T>(url: string, data?: T) {
  return axios.put(process.env.REACT_APP_ENDPOINT_ROOTAPI + url, data);
}

export async function post<T>(
  url: string,
  data: T,
  config?: AxiosRequestConfig,
) {
  return axios.post(process.env.REACT_APP_ENDPOINT_ROOTAPI + url, data, config);
}

export async function deleteData(
  url: string,
  params?: URLSearchParams,
  config: AxiosRequestConfig = {},
) {
  return axios.delete(process.env.REACT_APP_ENDPOINT_ROOTAPI + url, {
    params,
    ...config,
  });
}
