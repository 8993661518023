import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import RequestInterceptor from "../services/RequestInterceptor";
import ResponseInterceptor from "../services/ResponseInterceptor";
import "./App.css";
import AppRoutes from "./AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  typography: {
    allVariants: {
      color: "#3E4C58",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #E07738",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#E07738",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#E07738",
          },
          "&.Mui-disabled": {
            color: "#C7C7C7",
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RequestInterceptor />
      <ResponseInterceptor />
      <ToastContainer theme="colored" autoClose={3000} />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
