/* eslint-disable no-underscore-dangle */
import { IObservableArray, action, makeObservable, observable } from "mobx";
import PercentageModel from "./PercentageModel";
import QuestionModel from "./QuestionModel";

export default class QuestionsListModel {
  @observable questions: QuestionModel[] = [];
  @observable percentage: PercentageModel = new PercentageModel();

  constructor() {
    makeObservable(this);
  }

  @action
  setQuestionsList(questions: QuestionModel[]) {
    (this.questions as IObservableArray).replace(questions);
  }

  @action
  addQuestion(question: QuestionModel) {
    this.questions.push(new QuestionModel(question));
  }

  @action
  removeQuestion(index: number) {
    this.questions.splice(index, 1);
  }
}
