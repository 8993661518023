import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Coupon from "../pages/Coupon/Coupon";
import Main from "../pages/Main";
import Questions from "../pages/Questions/Questions";
import Upload from "../pages/Upload/Upload";
import AuthProtectedRoute from "../services/AuthProtectedRoutes/AuthProtectedRoutes";
import useAuth from "../services/AuthService/AuthService";
import LoadingSpinner from "../services/LoadingSpinner/LoadingSpinner";
import useLoadingSpinnerContext from "../services/LoadingSpinner/LoadingSpinnerService";
import Generating from "../pages/Generating/Generating";

function AppRoutes() {
  const auth = useAuth();
  const loadingSpinner = useLoadingSpinnerContext();

  return (
    <BrowserRouter>
      <Routes>
        {/* ROOT PATH */}
        <Route path="/" element={<Main />} />
        {/* MAIN PATHS */}
        <Route
          element={
            <>
              {loadingSpinner.getCounter() > 0 ? <LoadingSpinner /> : null}
              <Layout />
            </>
          }
        >
          <Route
            path="/coupon"
            element={
              <AuthProtectedRoute
                redirectTo="/"
                returnChildren={auth.getIsLoggedIn()}
              >
                <Coupon />
              </AuthProtectedRoute>
            }
          />
          <Route
            path="/upload"
            element={
              <AuthProtectedRoute
                redirectTo="/"
                returnChildren={auth.getIsLoggedIn()}
              >
                <Upload />
              </AuthProtectedRoute>
            }
          />
          <Route
            path="/questions"
            element={
              <AuthProtectedRoute
                redirectTo="/"
                returnChildren={auth.getIsLoggedIn()}
              >
                <Questions />
              </AuthProtectedRoute>
            }
          />
          <Route
            path="/generating"
            element={
              <AuthProtectedRoute
                redirectTo="/"
                returnChildren={auth.getIsLoggedIn()}
              >
                <Generating />
              </AuthProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
