/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import AddIcon from "@mui/icons-material/Add";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

type InputPathProps = {
  autocompleteValue: string;
  onChange: (value: string) => void;
  addpaths: (tag: string) => void;
  pathError: boolean;
  saveError: boolean;
};

const myEffect = keyframes`
10%, 90% {
  transform: translate3d(-1px, 0, 0);
}

20%, 80% {
  transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
  transform: translate3d(-4px, 0, 0);
}

40%, 60% {
  transform: translate3d(4px, 0, 0);
}
`;

export default function InputPath({
  autocompleteValue,
  onChange,
  addpaths,
  pathError,
  saveError
}: InputPathProps) {
  const { t } = useTranslation();
  const animatedItem = css`
    animation: ${myEffect} 1000ms;
  `;

  return (
    <div css={saveError ? animatedItem : ""}>
      <TextField
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // event.defaultMuiPrevented = true;
            addpaths((event.target as HTMLInputElement).value);
          }
        }}
        sx={{ mt: 3 }}
        onChange={(e) => {
          onChange(String(e.target.value));
        }}
        value={autocompleteValue}
        error={pathError}
        fullWidth
        variant="outlined"
        label={t("questions.insert-paths")}
        className="tagInput"
        helperText={t("questions.topics-helper-text")}
        InputProps={{
          endAdornment: (
            <Button
              variant="contained"
              disabled={!autocompleteValue}
              className="addButton"
              sx={{
                minHeight: "56px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                backgroundColor: "#E07738",
              }}
              onClick={() => addpaths(autocompleteValue)}
            >
              <AddIcon />
            </Button>
          ),
        }}
      />
    </div>
  );
}
