import { Button, Card, Grid, Typography } from "@mui/material";
import { t } from "i18next";

type ErrorProps = {
  type: string
};

function Error({ type }: ErrorProps) {
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "30px",
        p: 4,
        textAlign: "center",
      }}
    >
      <Grid container direction="column" justifyContent="center" gap={3}>
        <Grid item>
          <Typography variant="h5">{type === "coupon" ? t("coupon.error-coupon") : t("questions.error-questions")}</Typography>
        </Grid>
        <Grid item>
          <Typography>{type === "coupon" ? t("coupon.error-coupon-text") : t("questions.error-questions-text")}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              background: "linear-gradient(to right, #D74138, #EAAE2B)",
              borderTopLeftRadius: "30px",
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px",
            }}
            onClick={() =>
              window.location.replace(`https://${process.env.REACT_APP_VVIP_DOMAIN}`)
            }
          >
            {t("generating.return-to-vv")}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Error;
