import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from "react";
import storageType from "../storageService";
import Auth from "./Auth";

interface AuthProviderProps {
  children: ReactNode;
}

type AuthContextType = [Auth, React.Dispatch<React.SetStateAction<Auth>>];

const hasToken: string | null = storageType(
  `${process.env.REACT_APP_STORAGE}`,
).getItem(`${process.env.REACT_APP_NAME}-token`);

const initState: Auth = {
  isLoggedIn: !!hasToken,
  setToken: function (value: string): void {
    throw new Error("Function not implemented.");
  },
  getToken: function (queryToken?: string | null): string | null {
    throw new Error("Function not implemented.");
  },
  removeToken: function (): void {
    throw new Error("Function not implemented.");
  },
  setIsLoggedIn: function (state: boolean): void {
    throw new Error("Function not implemented.");
  },
  getIsLoggedIn: function (): boolean {
    throw new Error("Function not implemented.");
  },
};
const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: AuthProviderProps): ReactElement {
  const authState = useState<Auth>(initState);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

function useAuthContext(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("Please wrap this function into the AuthProvider");
  }
  return context;
}
export { AuthProvider, useAuthContext };
