import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { get } from "../services/ApiFunctions";
import useAuth from "../services/AuthService/AuthService";
import storageType from "../services/storageService";
import { UserStatus } from "../types/UserStatus";
import { UserStatusResponse } from "../types/UserStatusResponse";

function Main() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const savedToken = auth.getToken(searchParams.get("token"));
    if (!savedToken) {
      const token = searchParams.get("token");
      if (token) {
        auth.setToken(token);
        getUserStatus();
      } else {
        // show error?
        if (searchParams.get("question_id")) {
          window.location.replace(
            `https://${
              process.env.REACT_APP_VVIP_DOMAIN
            }/auth/login?redirect_to=${
              process.env.REACT_APP_QJ_DOMAIN
            }&question_id=${searchParams.get("question_id")}`
          );
        } else {
          window.location.replace(
            `https://${process.env.REACT_APP_VVIP_DOMAIN}/auth/login?redirect_to=${process.env.REACT_APP_QJ_DOMAIN}`
          );
        }
      }
    } else {
      getUserStatus();
    }
  }, [searchParams]);

  const getUserStatus = () => {
    // if token is exipired -> ResponseInterceptor handle this error
    get<UserStatusResponse>("/user-status")
      .then((res) => {
        if (
          res.data.status === UserStatus.READY &&
          (!storageType(`${process.env.REACT_APP_STORAGE}`).getItem("status") ||
            (storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
              "status"
            ) &&
              storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
                "status"
              ) === "UPLOADED" &&
              storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
                "ttl_status"
              ) &&
              new Date().getTime() -
                Number(
                  storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
                    "ttl_status"
                  )
                ) >
                300000))
        ) {
          if (storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
            "status"
          )) {
            storageType(`${process.env.REACT_APP_STORAGE}`).removeItem("status");
          }
          if (storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
            "ttl_status"
          )) {
            storageType(`${process.env.REACT_APP_STORAGE}`).removeItem("ttl_status");
          }
          navigate(`/coupon?status=${UserStatus.READY}`);
        } else if (
          res.data.status === UserStatus.GENERATING ||
          (res.data.status.split("-")[0] !== UserStatus.EMAIL_SENT &&
            res.data.status.split("-")[0] !== UserStatus.PAUSED &&
            storageType(`${process.env.REACT_APP_STORAGE}`).getItem("status"))
        ) {
          navigate(`/coupon?status=${UserStatus.GENERATING}`);
        } else if (
          res.data.status.split("-")[0] === UserStatus.EMAIL_SENT ||
          res.data.status.split("-")[0] === UserStatus.PAUSED
        ) {
          storageType(`${process.env.REACT_APP_STORAGE}`).removeItem("status");
          let question_id =
            searchParams.get("question_id") ||
            res.data.status.split("-")[1] ||
            null;
          if (question_id) {
            storageType(`${process.env.REACT_APP_STORAGE}`).setItem(
              "question_id",
              question_id
            );
          } else if (
            storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
              "question_id"
            )
          ) {
            question_id = storageType(
              `${process.env.REACT_APP_STORAGE}`
            ).getItem("question_id");
          }
          if (question_id) {
            navigate(`/questions?id=${question_id}`);
          } else {
            navigate(`/coupon?status=${UserStatus.ERROR}`);
          }
        }
      })
      .catch(() => {
        toast.error(t("coupon.error-user-status"));
      });
  };

  return null;
}

export default Main;
