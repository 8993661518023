import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";

function Layout() {
  return (
    <Box sx={{ height: "100vh" }}>
      <Header />
      <Box sx={{ height: "100%", pt: "4rem" }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
