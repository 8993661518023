import { TextField } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

type QuestionInputProps = {
  newQuestion: boolean;
  type: string;
  value: string;
  onChange: (e: string) => void;
};

export default memo(function QuestionInput({
  newQuestion,
  type,
  value,
  onChange,
}: QuestionInputProps) {
  const { t } = useTranslation()

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      placeholder={newQuestion && type === "question" ? t("questions.question") : newQuestion && type === "correct_answer" ? t("questions.correct-answer") : newQuestion && type === "distractor_1" ? `${t("questions.wrong-answer")} 1` : newQuestion && type === "distractor_2" ? `${t("questions.wrong-answer")} 2` : ""}
      value={value}
      sx={{ mb: 1 }}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    ></TextField>
  );
});
