import { Button, Card, Grid, Typography } from "@mui/material";
import { t } from "i18next";

function Generating() {
  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item xs={4}></Grid>
      <Grid
        item
        xs={4}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Card
          sx={{
            width: "100%",
            borderRadius: "30px",
            p: 4,
            textAlign: "center",
          }}
        >
          <Grid container direction="column" justifyContent="center" gap={3}>
            <Grid item>
              <Typography>{t("generating.generating")}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  background: "linear-gradient(to right, #D74138, #EAAE2B)",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                }}
                onClick={() =>
                  window.location.replace(`https://${process.env.REACT_APP_VVIP_DOMAIN}`)
                }
              >
                {t("generating.return-to-vv")}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

export default Generating;