/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import TranslateIcon from "@mui/icons-material/Translate";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { t } from "i18next";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import QuestionModel from "../../models/QuestionModel";
import { Tag, Translation } from "../../types/Question";
import HabitInput from "./HabitInput";
import QuestionInput from "./QuestionInput";

type QuestionProps = {
  q: QuestionModel;
  qIndex: number;
  saveError: boolean;
  onSetEvaluatedQuestions: (value: number) => void;
  onRemoveQuestion: () => void;
  onSetNextHabit: () => void;
  paths: Tag[];
};

const myEffect = keyframes`
10%, 90% {
  transform: translate3d(-1px, 0, 0);
}

20%, 80% {
  transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
  transform: translate3d(-4px, 0, 0);
}

40%, 60% {
  transform: translate3d(4px, 0, 0);
}
`;

function QuestionCard({
  q,
  qIndex,
  saveError,
  onSetEvaluatedQuestions,
  onRemoveQuestion,
  onSetNextHabit,
  paths,
}: QuestionProps) {
  const [initialState, setInitialState] = useState<QuestionModel | null>(null);
  const animatedItem = css`
    animation: ${myEffect} 1000ms;
  `;

  useEffect(() => {
    setInitialState(new QuestionModel(q));
  }, []);

  const onUndoEdit = () => {
    if (initialState && q.newQuestion) {
      onRemoveQuestion();
    } else if (initialState) {
      q.reset(initialState);
    }
  };

  const translateQuestion = async () => {
    try {
      const res = await axios.post<Translation>(
        `https://${process.env.REACT_APP_VVIP_DOMAIN}/api/translate`,
        JSON.stringify({
          textLang: q.language === "en" ? "it" : "en",
          sourceLang: q.language,
          text: q.question,
          answers: [q.correct_answer, q.distractor_1, q.distractor_2],
        }),
        {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        }
      );
      q.setQuestion(res.data.translatedText);
      q.setCurrentAnswer(res.data.translatedAnswers[0]);
      q.setDistractor1(res.data.translatedAnswers[1]);
      q.setDistractor2(res.data.translatedAnswers[2]);
      q.setLanguage(q.language === "en" ? "it" : "en");
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };

  const setEnglishLanguage = async (question: QuestionModel) => {
    try {
      const res = await axios.post<Translation>(
        `https://${process.env.REACT_APP_VVIP_DOMAIN}/api/translate`,
        JSON.stringify({
          textLang: "en",
          sourceLang: "auto",
          text: question.question,
          answers: [
            question.correct_answer,
            question.distractor_1,
            question.distractor_2,
          ],
        }),
        {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        }
      );
      question.setQuestion(res.data.translatedText);
      question.setCurrentAnswer(res.data.translatedAnswers[0]);
      question.setDistractor1(res.data.translatedAnswers[1]);
      question.setDistractor2(res.data.translatedAnswers[2]);
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };

  const editQuestion = () => {
    setInitialState(new QuestionModel(q));
    if (q.newQuestion) {
      q.setNewQuestion(false);
      setEnglishLanguage(q);
    }
    q.setEditable(false);
  };

  return (
    <Card
      id={`evaluated-${qIndex}`}
      sx={{
        width: "100%",
        borderRadius: "30px",
        mr: 4,
        mt: 4,
        mb: 4,
        p: 2,
        textAlign: "center",
      }}
      key={qIndex}
    >
      <CardActions sx={{ py: 0 }}>
        <Grid container>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <FormGroup>
              <FormControlLabel
                sx={{ pointerEvents: "none" }}
                control={
                  <div css={saveError ? animatedItem : ""}>
                    <Checkbox
                      sx={{
                        color: "#E07738",
                        "&.Mui-checked": {
                          color: "#E07738",
                        },
                        pointerEvents: "auto",
                      }}
                      checked={q.evaluated}
                      disabled={q.habitError || q.newQuestion}
                      onClick={() => {
                        q.setEvaluated(!q.evaluated);
                        if (q.evaluated) {
                          onSetEvaluatedQuestions(1);
                        } else {
                          onSetEvaluatedQuestions(-1);
                        }
                      }}
                    />
                  </div>
                }
                label={t("questions.confirm-question")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            {!q.editable && !q.evaluated && (
              <>
                <Tooltip title={t("questions.translate")}>
                  <IconButton onClick={() => translateQuestion()}>
                    <TranslateIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("questions.edit")}>
                  <IconButton onClick={() => q.setEditable(!q.editable)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("questions.delete")}>
                  <IconButton onClick={() => onRemoveQuestion()}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {q.editable && !q.evaluated && (
              <>
                <Tooltip title={t("questions.cancel")}>
                  <IconButton onClick={() => onUndoEdit()}>
                    <UndoIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("questions.save")}>
                  <IconButton onClick={() => editQuestion()} disabled={!q.isCorrect}>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
        </Grid>
      </CardActions>
      <CardContent sx={{ textAlign: "left" }}>
        {q.editable ? (
          <FormControl key={"question-" + qIndex} fullWidth>
            <QuestionInput
              newQuestion={q.newQuestion}
              type="question"
              value={q.question}
              onChange={(value) => q.setQuestion(value)}
            />
          </FormControl>
        ) : (
          <Typography
            sx={{
              mb: 1,
              fontWeight: "bold",
              color: q.evaluated ? "#C7C7C7" : "auto",
            }}
          >
            {qIndex + 1}. {q.question}
          </Typography>
        )}
        {q.editable ? (
          <QuestionInput
            newQuestion={q.newQuestion}
            type="correct_answer"
            value={q.correct_answer}
            onChange={(value) => q.setCurrentAnswer(value)}
          />
        ) : (
          <Typography
            sx={{
              color: q.evaluated ? "#C7C7C7" : "green",
              mb: 1.5,
            }}
          >
            {q.correct_answer}
          </Typography>
        )}
        {q.editable ? (
          <QuestionInput
            newQuestion={q.newQuestion}
            type="distractor_1"
            value={q.distractor_1}
            onChange={(value) => q.setDistractor1(value)}
          />
        ) : (
          <Typography
            sx={{
              color: q.evaluated ? "#C7C7C7" : "#a70000",
              mb: 1.5,
            }}
          >
            {q.distractor_1}
          </Typography>
        )}
        {q.editable ? (
          <QuestionInput
            newQuestion={q.newQuestion}
            type="distractor_2"
            value={q.distractor_2}
            onChange={(value) => q.setDistractor2(value)}
          />
        ) : (
          <Typography
            sx={{
              color: q.evaluated ? "#C7C7C7" : "#a70000",
              mb: 1.5,
            }}
          >
            {q.distractor_2}
          </Typography>
        )}
        <hr />
        <Grid container sx={{ mt: 1 }}  spacing={{xs: 3, xl: 0}}>
          <Grid item xs={5}>
            <HabitInput
              key={qIndex + "habit"}
              value={q.habit}
              saveError={saveError}
              onChange={(value) => {
                if (value === 0) {
                  q.setHabit(undefined);
                } else {
                  q.setHabit(value);
                }
              }}
              habitDisabled={q.evaluated || false}
              habitError={q.habitError}
            />
          </Grid>
          {q.evaluated ? (
            <Grid item xs={1}>
              <InfoIcon
                style={{
                  color: "#3E4C58",
                  marginTop: "1rem",
                  display: "block",
                  marginLeft: "0.75rem",
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={1}>
              <Tooltip title={t("questions.habit-tooltip-text")}>
                <InfoIcon
                  style={{
                    color: "#3E4C58",
                    marginTop: "1rem",
                    display: "block",
                    marginLeft: "0.75rem",
                  }}
                />
              </Tooltip>
            </Grid>
          )}
          {q.habit &&
          q.habit >= 1 &&
          q.habit <= 8 &&
          paths &&
          paths.length &&
          paths[q.habit - 1] ? (
            <Grid item xs={5} sx={{ display: "flex" }}>
              <Box sx={{ width: "100%", textAlign: {xs: 'center', xl: 'left'} }}>
                <Chip
                  label={paths[q.habit - 1].label}
                  variant="outlined"
                  sx={{
                    mt: "0.45rem",
                    fontSize: "1rem",
                    height: "auto",
                    p: 0.5,
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      wordBreak: "break-all",
                    },
                  }}
                  icon={
                    <>
                      <Avatar
                        sizes="small"
                        sx={{
                          width: 30,
                          height: 30,
                          background:
                            "linear-gradient(to right, #D74138, #EAAE2B)",
                          ml: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontSize: "1.25rem",
                          }}
                        >
                          {q.habit}
                        </Typography>
                      </Avatar>
                    </>
                  }
                />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={5} />
          )}
          <Grid item xl={7} xs={12}>
            <Button
              variant="contained"
              sx={{
                background:
                  q.evaluated || q.habitError
                    ? "#C7C7C7"
                    : "linear-gradient(to right, #D74138, #EAAE2B)",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomRightRadius: "30px",
                mt: 1,
              }}
              disabled={q.evaluated || q.habitError}
              onClick={() => {
                onSetNextHabit();
              }}
            >
              {t("questions.set-next-questions")}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default observer(QuestionCard);
