/* eslint-disable no-underscore-dangle */
import { computed, makeObservable, observable } from "mobx";

export default class PercentageModel {
  @observable value: number | undefined;

  constructor() {
    makeObservable(this);
  }

  setValue(newValue: number | undefined) {
    this.value = newValue;
  }

  @computed
  get percentageError(): boolean {
    return (
      !this.value ||
      this.value > 100 ||
      this.value < 0
    );
  }
}
