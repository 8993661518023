/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { InputAdornment, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import TextMaskCustom from '../../components/Layout/TextMaskCustom';
import PercentageModel from '../../models/PercentageModel';
import './Questions.css';

type PercentageInputProps = {
  model: PercentageModel;
  saveError: boolean;
};

const myEffect = keyframes`
10%, 90% {
  transform: translate3d(-1px, 0, 0);
}

20%, 80% {
  transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
  transform: translate3d(-4px, 0, 0);
}

40%, 60% {
  transform: translate3d(4px, 0, 0);
}
`;

function PercentageInput({ model, saveError }: PercentageInputProps) {
  const { t } = useTranslation();
  const animatedItem = css`
  animation: ${myEffect} 1000ms;
`;

  return (
    <div css={saveError ? animatedItem : ""}>
      <TextField
        label={t('questions.custom-question-percentage')}
        size="small"
        className="percentageInput"
        fullWidth
        value={model.value}
        onChange={(event) => {
          if (Number(event.target.value) === 0) {
            model.setValue(undefined);
          } else {
            model.setValue(Number(event.target.value));
          }
        }}
        required
        InputLabelProps={{
          style: {
            fontSize: 10.5,
          },
        }}
        helperText={t('questions.percentage-helper-label')}
        error={model.percentageError}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ pr: 1 }} position="end">
              %
            </InputAdornment>
          ),
          inputComponent: TextMaskCustom as any,
        }}
      />
    </div>
  );
}

export default observer(PercentageInput);
