/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextMaskCustom from "../../components/Layout/TextMaskCustom";

type HabitInputProps = {
  value: number | undefined;
  onChange: (value: number) => void;
  habitDisabled: boolean;
  habitError: boolean;
  saveError: boolean;
};

const myEffect = keyframes`
10%, 90% {
  transform: translate3d(-1px, 0, 0);
}

20%, 80% {
  transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
  transform: translate3d(-4px, 0, 0);
}

40%, 60% {
  transform: translate3d(4px, 0, 0);
}
`;

export default function HabitInput({
  value,
  onChange,
  habitDisabled,
  habitError,
  saveError,
}: HabitInputProps) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const animatedItem = css`
    animation: ${myEffect} 1000ms;
  `;

  useEffect(() => {
    setError(habitError);
  }, [habitError]);

  return (
    <div css={saveError ? animatedItem : ""}>
      <TextField
        label={t("questions.habit")}
        size="small"
        disabled={habitDisabled}
        value={value}
        sx={{ mt: 1 }}
        fullWidth
        onChange={(event) => {
          onChange(Number(event.target.value));
        }}
        error={error}
        InputProps={{
          inputComponent: TextMaskCustom as any,
        }}
      />
    </div>
  );
}
