import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { FilePondFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import { t } from "i18next";
import { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { toast } from "react-toastify";
import { post } from "../../services/ApiFunctions";
import useLoadingSpinnerContext from "../../services/LoadingSpinner/LoadingSpinnerService";
import storageType from "../../services/storageService";
import { PresignedUrlResponse } from "../../types/PresignedUrlResponse";
import "./Upload.css";

interface Tag {
  key: number;
  label: string;
}

interface FileData {
  file: ArrayBuffer;
  size: number;
  type: string;
  name: string;
}

function Upload() {
  const loadingSpinner = useLoadingSpinnerContext();
  registerPlugin(FilePondPluginFileValidateType);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [tags, setTags] = useState<Tag[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<string>("");
  const [pond, setPond] = useState<FilePond | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [fileData, setFileData] = useState<FileData | null>(null);

  const handleUpload = () => {
    if (fileData) {
      loadingSpinner.addRequest();
      fetch(presignedUrl, {
        method: "PUT",
        body: fileData.file,
      })
        .then(() => {
          loadingSpinner.addResponse();
          setActiveStep(3);
          storageType(`${process.env.REACT_APP_STORAGE}`).setItem(
            "ttl_status",
            String(new Date().getTime())
          );
          storageType(`${process.env.REACT_APP_STORAGE}`).setItem(
            "status",
            "UPLOADED"
          );
        })
        .catch(() => {
          loadingSpinner.addResponse();
          toast.error(t("upload.error-uploading-file"));
        });
    }
  };

  const handleInit = () => {
    console.log("FilePond instance has initialised", pond);
  };

  const addTags = (tag: string) => {
    const newTags: Tag[] = [];
    if (tag === "") {
      return;
    }
    if (tag.includes(",")) {
      for (let i = 0; i < tag.split(",").length; i++) {
        newTags.push({ key: tags.length + i, label: tag.split(",")[i].trim() });
      }
    } else {
      newTags.push({ key: tags.length, label: tag });
    }
    setTags((current) => [...current, ...newTags]);
    setAutocompleteValue("");
  };

  const deleteTag = (tagToDelete: Tag) => {
    setTags((tags) => tags.filter((tags) => tags.key !== tagToDelete.key));
  };

  const generatePresignedUrl = (filePond: FilePondFile): Promise<boolean> => {
    return post(
      `/presigned/?extension=${filePond.fileExtension}&filename=${filePond.filenameWithoutExtension}`,
      {
        topics: tags.map((tag) => tag.label),
      }
    )
      .then(async (res) => {
        await createFileFromPond(filePond);
        setPresignedUrl((res.data as PresignedUrlResponse).presigned_url);
        return true;
      })
      .catch((err) => {
        toast.error(t("upload.error-uploading-file"));
        return false;
      });
  };

  const createFileFromPond = async (filePond: FilePondFile) => {
    const fileData: FileData = {
      file: await filePond.file.arrayBuffer(),
      size: filePond.fileSize,
      type: filePond.fileType,
      name: filePond.filename,
    };
    setFileData(fileData);
  };

  const downloadFile = async () => {
    if (fileData) {
      const blob = new Blob([fileData.file]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileData.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={4}></Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container direction="column">
            <Grid item xs={11}>
              <Card
                sx={{
                  width: "100%",
                  borderRadius: "30px",
                  p: 4,
                  textAlign: "center",
                }}
              >
                <CardHeader
                  title={
                    <Stepper activeStep={activeStep}>
                      <Step completed={activeStep > 0}>
                        <StepLabel>{t("upload.insert-topics")}</StepLabel>
                      </Step>
                      <Step completed={activeStep > 1}>
                        <StepLabel>{t("upload.file-upload")}</StepLabel>
                      </Step>
                      <Step completed={activeStep > 2}>
                        <StepLabel>{t("upload.summary")}</StepLabel>
                      </Step>
                    </Stepper>
                  }
                />
                {activeStep === 0 && (
                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      gap={2}
                    >
                      <Grid item>
                        <Typography variant="h5">
                          {t("upload.question-topics")}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ mt: 1, textAlign: "justify" }}
                        >
                          {t("upload.question-topics-subtitle")}
                        </Typography>
                      </Grid>
                      <Grid item className="filepond-container">
                        {tags.map((tag: Tag) => (
                          <Chip
                            key={tag.key}
                            sx={{ mr: 0.5, mb: 1 }}
                            variant="outlined"
                            label={tag.label}
                            onDelete={() => deleteTag(tag)}
                          />
                        ))}

                        <TextField
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              // event.defaultMuiPrevented = true;
                              addTags((event.target as HTMLInputElement).value);
                            }
                          }}
                          sx={{ mt: 1 }}
                          onChange={(e) => setAutocompleteValue(e.target.value)}
                          value={autocompleteValue}
                          fullWidth
                          variant="outlined"
                          label={t("upload.topics")}
                          className="tagInput"
                          helperText={t("upload.topics-helper-text")}
                          InputProps={{
                            endAdornment: (
                              <Button
                                variant="contained"
                                disabled={!autocompleteValue}
                                className="addButton"
                                sx={{
                                  minHeight: "56px",
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  backgroundColor: "#E07738",
                                }}
                                onClick={() => addTags(autocompleteValue)}
                              >
                                <AddIcon />
                              </Button>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          disabled={!tags || !tags.length}
                          sx={{
                            background:
                              tags && tags.length
                                ? "linear-gradient(to right, #D74138, #EAAE2B)"
                                : "#C7C7C7",
                            borderTopLeftRadius: "30px",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                          }}
                          onClick={() => setActiveStep(1)}
                        >
                          {t("upload.next")}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                )}
                {activeStep === 1 && (
                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      gap={3}
                    >
                      <Grid item>
                        <Typography variant="h5">
                          {t("upload.file-upload")}
                        </Typography>
                      </Grid>
                      <Grid item className="filepond-container">
                        <FilePond
                          ref={(ref) => {
                            setPond(ref);
                          }}
                          allowMultiple={false}
                          credits={false}
                          name="file"
                          oninit={() => handleInit()}
                          acceptedFileTypes={[
                            "application/pdf",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "text/plain",
                          ]}
                          beforeAddFile={async (filePond: FilePondFile) =>
                            await generatePresignedUrl(filePond)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{
                            background:
                              "linear-gradient(to right, #D74138, #EAAE2B)",
                            borderTopLeftRadius: "30px",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                            mr: 2,
                          }}
                          onClick={() => setActiveStep(0)}
                        >
                          {t("upload.back")}
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            background: fileData
                              ? "linear-gradient(to right, #D74138, #EAAE2B)"
                              : "#C7C7C7",
                            borderTopLeftRadius: "30px",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                          }}
                          disabled={!fileData}
                          onClick={() => setActiveStep(2)}
                        >
                          {t("upload.next")}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                )}
                {activeStep === 2 && (
                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      gap={3}
                    >
                      <Grid item>
                        <Typography variant="h5">
                          {t("upload.summary")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {t("upload.inserted-topics")}: <br />
                        {tags.map((tag: Tag) => (
                          <Chip
                            key={tag.key}
                            sx={{ my: 2, mr: 0.5 }}
                            variant="outlined"
                            label={tag.label}
                          />
                        ))}
                        <br />
                        {t("upload.uploaded-file")}{" "}
                        <a href="#" onClick={() => downloadFile()}>
                          {fileData?.name}
                        </a>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{
                            background:
                              "linear-gradient(to right, #D74138, #EAAE2B)",
                            borderTopLeftRadius: "30px",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                            mr: 2,
                          }}
                          onClick={() => setActiveStep(1)}
                        >
                          {t("upload.back")}
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            background:
                              "linear-gradient(to right, #D74138, #EAAE2B)",
                            borderTopLeftRadius: "30px",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                          }}
                          onClick={() => handleUpload()}
                        >
                          {t("coupon.confirm")}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                )}
                {activeStep === 3 && (
                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      gap={3}
                    >
                      <Grid item>
                        <Typography variant="h5">
                          {t("upload.success")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{t("upload.success-text")}</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{
                            background:
                              "linear-gradient(to right, #D74138, #EAAE2B)",
                            borderTopLeftRadius: "30px",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                          }}
                          onClick={() =>
                            window.location.replace(
                              `https://${process.env.REACT_APP_VVIP_DOMAIN}`
                            )
                          }
                        >
                          {t("generating.return-to-vv")}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                )}
              </Card>
            </Grid>
            <Grid item xs={1} sx={{ textAlign: "center" }}>
              {activeStep !== 3 && (
                <Button
                  variant="contained"
                  sx={{
                    mt: 4,
                    background: "linear-gradient(to right, #D74138, #EAAE2B)",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px",
                  }}
                  onClick={() =>
                    window.location.replace(
                      `https://${process.env.REACT_APP_VVIP_DOMAIN}`
                    )
                  }
                >
                  {t("generating.return-to-vv")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </>
  );
}
export default Upload;
