import axios from "axios";
import { useEffect, useState } from "react";
import useAuth from "./AuthService/AuthService";
import useLoadingSpinnerContext from "./LoadingSpinner/LoadingSpinnerService";

export default function RequestInterceptor() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const loadingSpinner = useLoadingSpinnerContext();
  const auth = useAuth();

  const initializeInterceptor = () => {
    setIsActive(true);
    return axios.interceptors.request.use(
      async (request) => {
        async function getToken(): Promise<string | Error> {
          const token = auth.getToken();
          if (token) {
            return token;
          }
          return new Error("token not provided");
        }
        loadingSpinner.addRequest();
        if (request && request.headers) {
          request.headers["Authorization"] = `Bearer ${await getToken()}`;
        }

        return request;
      },
      (error) => {
        loadingSpinner.addResponse();
        Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    if (!isActive) {
      initializeInterceptor();
    }
  }, []);

  return null;
}
