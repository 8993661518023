import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "../../services/ApiFunctions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function InsertCoupon() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [couponCode, setCouponCode] = useState<string>("");

  const handleConfirm = () => {
    post("/coupon", { coupon: couponCode })
      .then(() => {
        navigate("/upload");
      })
      .catch(() => {
        toast.error(t("coupon.error-400-coupon"));
      });
  };

  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "30px",
        p: 4,
        textAlign: "center",
      }}
    >
      <Grid container direction="column" justifyContent="center" gap={3}>
        <Grid item sx={{ justifyContent: "center", display: "flex" }}>
          <Typography variant="h5">{t("coupon.insert-coupon")}</Typography>
          <Box sx={{ alignSelf: "center" }}>
            <Tooltip placement="right" title={t("coupon.platform-info")}>
              <InfoIcon
                style={{
                  color: "#3E4C58",
                  marginLeft: "0.5rem",
                }}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label={t("coupon.code")}
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          ></TextField>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={!couponCode}
            sx={{
              background: couponCode
                ? "linear-gradient(to right, #D74138, #EAAE2B)"
                : "#C7C7C7",
              borderTopLeftRadius: "30px",
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px",
            }}
            onClick={() => handleConfirm()}
          >
            {t("coupon.confirm")}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default InsertCoupon;
