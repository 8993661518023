import React from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { value: number } }) => void;
  name: string;
}
const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={Number}
        inputRef={ref}
        unmask="typed" // true|false|'typed'
        onAccept={
          // depending on prop above first argument is
          // `value` if `unmask=false`,
          // `unmaskedValue` if `unmask=true`,
          // `typedValue` if `unmask='typed'`
          (typedValue, mask) => {
            onChange({ target: { value: Number(typedValue) } });
          }
        }
        scale={0}
        normalizeZeros={false}
        overwrite
        padFractionalZeros
      />
    );
  }
);

export default TextMaskCustom;
