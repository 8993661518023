import { jwtDecode } from "jwt-decode";
import storageType from "../storageService";
import Auth from "./Auth";
import { useAuthContext } from "./AuthServiceProvider";

const useAuth = (): Auth => {
  const [auth, setAuth] = useAuthContext();

  const hasToken: string | null = storageType(
    `${process.env.REACT_APP_STORAGE}`
  ).getItem(`${process.env.REACT_APP_NAME}-token`);

  const isTokenExpired = (token: string) =>
    Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000;

  return {
    isLoggedIn: !!hasToken,
    setToken(value: string): void {
      storageType(`${process.env.REACT_APP_STORAGE}`).setItem(
        `${process.env.REACT_APP_NAME}-token`,
        value
      );
      const decoded = jwtDecode(value);
      storageType(`${process.env.REACT_APP_STORAGE}`).setItem(
        "user-email",
        (decoded as any).email
      );
      this.setIsLoggedIn(true);
    },
    getToken(queryToken?: string | null): string | null {
      const token = storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
        `${process.env.REACT_APP_NAME}-token`
      );
      if (queryToken) {
        let urlToken = queryToken;
        const decoded = urlToken ? jwtDecode(urlToken) : null;
        if (
          decoded &&
          storageType(`${process.env.REACT_APP_STORAGE}`).getItem("user-email") &&
          storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
            "user-email"
          ) !== (decoded as any).email
        ) {
          localStorage.clear();
          this.setToken(urlToken as string);
          return urlToken as string;
        }
      }
      if (token && !isTokenExpired(token)) {
        return token;
      } else if (token && isTokenExpired(token)) {
        storageType(`${process.env.REACT_APP_STORAGE}`).removeItem(
          `${process.env.REACT_APP_NAME}-token`
        );
      }
      return null;
    },
    removeToken(): void {
      storageType(`${process.env.REACT_APP_STORAGE}`).removeItem(
        `${process.env.REACT_APP_NAME}-token`
      );
      this.setIsLoggedIn(false);
    },
    setIsLoggedIn(state: boolean) {
      this.isLoggedIn = state;
      setAuth(this);
    },
    getIsLoggedIn() {
      return auth.isLoggedIn;
    },
  };
};

export default useAuth;
