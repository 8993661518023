import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type NoQuestionsDialogProps = {
  open: boolean;
  onClose: (returnValue: string) => void;
};

function NoQuestionsDialog({
  open,
  onClose,
}: NoQuestionsDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle>
        <Typography sx={{ pt: 1, fontWeight: "bold" }}>
          {t("questions.no-questions")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            {t("questions.no-questions-info")}
          </Typography>
        </DialogContentText>
        <Box sx={{ pt: 2, textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                background: "linear-gradient(to right, #D74138, #EAAE2B)",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomRightRadius: "30px",
                mr: 2
              }}
              onClick={() => onClose("add")}
            >
              {t("questions.add-question")}
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "linear-gradient(to right, #D74138, #EAAE2B)",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomRightRadius: "30px",
              }}
              onClick={() => onClose("return")}
            >
              {t("generating.return-to-vv")}
            </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default NoQuestionsDialog;
