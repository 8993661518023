import { Box, CircularProgress } from "@mui/material";
import LoadingSpinnerStyle from "./LoadingSpinnerStyle";

export default function LoadingSpinner() {
  return (
    <Box sx={LoadingSpinnerStyle.LoadingSpinnerContainer()}>
      <CircularProgress style={{ "color": "#E07738" }}/>
    </Box>
  );
}
