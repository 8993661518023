import axios from "axios";
import { useEffect, useState } from "react";
import useAuth from "./AuthService/AuthService";
import useLoadingSpinnerContext from "./LoadingSpinner/LoadingSpinnerService";

export default function ResponseInterceptor() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const loadingSpinner = useLoadingSpinnerContext();
  const auth = useAuth();

  const initializeInterceptor = () => {
    setIsActive(true);
    return axios.interceptors.response.use(
      (response) => {
        loadingSpinner.addResponse();
        return Promise.resolve(response);
      },
      (error) => {
        loadingSpinner.addResponse();
        if (error.response.status && error.response.status === 401) {
          auth.removeToken();
          window.location.href = `https://${process.env.REACT_APP_VVIP_DOMAIN}/auth/login`;
        }
        return Promise.reject(error);
      },
    );
  };

  useEffect(() => {
    if (!isActive) {
      initializeInterceptor();
    }
  }, []);

  return null;
}
