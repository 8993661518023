import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import InfoIcon from "@mui/icons-material/Info";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { t } from "i18next";
import { observer, useLocalObservable } from "mobx-react";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import QuestionModel from "../../models/QuestionModel";
import QuestionsListModel from "../../models/QuestionsListModel";
import { get, post, put } from "../../services/ApiFunctions";
import storageType from "../../services/storageService";
import { PostQuestion, Question, Tag, Translation } from "../../types/Question";
import { UserStatus } from "../../types/UserStatus";
import { UserStatusResponse } from "../../types/UserStatusResponse";
import ConfirmationDialog from "./ConfirmationDialog";
import InputPath from "./InputPath";
import NoQuestionsDialog from "./NoQuestionsDialog";
import PercentageInput from "./PercentageInput";
import QuestionCard from "./QuestionCard";
import "./Questions.css";

function Questions() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [paths, setPaths] = useState<Tag[]>([]);
  const [ready, setReady] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [evaluatedQuestions, setEvaluatedQuestions] = useState(0);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [openNoQuestionsDialog, setOpenNoQuestionsDialog] =
    useState<boolean>(false);
  const [fileName, setFileName] = useState("");
  const [saveErrorPercentage, setSaveErrorPercentage] = useState(false);
  const [saveErrorHabit, setSaveErrorHabit] = useState(false);
  const [saveErrorPath, setSaveErrorPath] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState<string>("");
  const [pathError, setPathError] = useState<boolean>(paths.length !== 8);

  const questionsList = useLocalObservable(() => new QuestionsListModel());

  const setInitialPaths = () => {
    get("/paths")
      .then((res: any) => {
        const mappedResponse = res.data.paths.map(
          (path: string, idx: number) =>
            ({ key: paths.length + idx, label: path } as Tag)
        );
        setPaths([...mappedResponse]);
      })
      .catch(() => {
        toast.error(t("questions.error-get-paths"));
      });
  };

  useEffect(() => {
    let resume = false;
    get("/filename?latest=true")
      .then((res: any) => {
        setFileName(res.data.filename.file_name || "");
      })
      .catch(() => {
        toast.error(t("questions.file-name-error"));
      });
    get<UserStatusResponse>("/user-status")
      .then((res) => {
        if (
          res.data.status === UserStatus.READY &&
          !storageType(`${process.env.REACT_APP_STORAGE}`).getItem("status")
        ) {
          navigate(`/coupon?status=${UserStatus.READY}`);
        } else if (
          res.data.status === UserStatus.GENERATING ||
          (res.data.status.split("-")[0] !== UserStatus.EMAIL_SENT &&
            res.data.status.split("-")[0] !== UserStatus.PAUSED &&
            storageType(`${process.env.REACT_APP_STORAGE}`).getItem("status"))
        ) {
          navigate(`/coupon?status=${UserStatus.GENERATING}`);
        } else if (res.data.status.split("-")[0] === UserStatus.PAUSED) {
          storageType(`${process.env.REACT_APP_STORAGE}`).removeItem("status");
          resume = true;
          get<{ questions: Question[]; percentage: number }>("/resume-work")
            .then((res) => {
              let evaluated = 0;
              for (let i = 0; i < res.data.questions.length; i++) {
                const q = new QuestionModel(res.data.questions[i]);
                questionsList.addQuestion(q);
                if (q.evaluated) {
                  evaluated += 1;
                }
              }
              setEvaluatedQuestions(evaluated);
              questionsList.percentage.setValue(res.data.percentage);
              setReady(true);
              setTimeout(() => {
                document
                  .getElementById(`evaluated-${evaluated - 1}`)
                  ?.scrollIntoView({ behavior: "smooth" });
              }, 1000);
            })
            .catch(() => toast.error(t("questions.error-questions")));
        } else if (!resume) {
          storageType(`${process.env.REACT_APP_STORAGE}`).removeItem("status");
          let question_id;
          if (res.data.status.split("-")[0] === UserStatus.EMAIL_SENT) {
            question_id = res.data.status.split("-")[1];
          } else {
            question_id =
              searchParams.get("id") ||
              storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
                "question_id"
              );
          }
          if (question_id) {
            get<Question[]>(`/${question_id}`)
              .then((res) => {
                for (let i = 0; i < res.data.length; i++) {
                  const q = new QuestionModel(res.data[i]);
                  questionsList.addQuestion(q);
                }
                setReady(true);
              })
              .catch(() => toast.error(t("questions.error-questions")));
          }
        }
      })
      .catch(() => toast.error(t("coupon.error-user-status")));
    setInitialPaths();
  }, [searchParams]);

  const pauseSession = (
    questions: Question[],
    percentage: number | undefined
  ) => {
    const body = {
      questions,
      percentage,
    };
    post("/save-work", body)
      .then(() => {
        window.location.replace(`https://${process.env.REACT_APP_VVIP_DOMAIN}`);
      })
      .catch(() => toast.error(t("questions.error-pause")));
  };

  const translateQuestion = async (question: QuestionModel) => {
    try {
      const res = await axios.post<Translation>(
        `https://${process.env.REACT_APP_VVIP_DOMAIN}/api/translate`,
        JSON.stringify({
          textLang: question.language === "en" ? "it" : "en",
          sourceLang: question.language,
          text: question.question,
          answers: [
            question.correct_answer,
            question.distractor_1,
            question.distractor_2,
          ],
        }),
        {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        }
      );
      question.setQuestion(res.data.translatedText);
      question.setCurrentAnswer(res.data.translatedAnswers[0]);
      question.setDistractor1(res.data.translatedAnswers[1]);
      question.setDistractor2(res.data.translatedAnswers[2]);
      question.setLanguage(question.language === "en" ? "it" : "en");
      return question;
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };

  const saveQuestions = async () => {
    try {
      const translatedQuestions = questionsList.questions;
      setIsSaving(true);
      for (let i = 0; i < questionsList.questions.length; i++) {
        if (
          questionsList.questions[i].language &&
          questionsList.questions[i].language === "it"
        ) {
          const questionTranslated = await translateQuestion(
            questionsList.questions[i]
          );
          translatedQuestions.splice(i, 1, questionTranslated);
        }
      }
      const noLanguageQuestions = translatedQuestions.map(
        ({ language, evaluated, ...rest }) => {
          if (rest.habit) {
            rest.habit = rest.habit - 1;
          }
          return rest;
        }
      );
      const body: PostQuestion = {
        probability: Number(questionsList.percentage.value) / 100,
        questions: noLanguageQuestions,
      };
      post<PostQuestion>("", body)
        .then(() => {
          localStorage.clear();
          navigate("/generating");
        })
        .catch(() => {
          toast.error(t("questions.error-saving-questions"));
        })
        .finally(() => {
          setIsSaving(false);
        });
    } catch (err) {
      toast.error(t("questions.error-saving-questions"));
    }
  };

  const handleConfirmationDialogClose = async (returnValue: boolean) => {
    setOpenConfirmationDialog(false);
    if (returnValue === true) {
      saveQuestions();
    }
  };

  const handleNoQuestionsDialogClose = (value: string) => {
    setOpenNoQuestionsDialog(false);
    if (value === "return") {
      put("/user-status").then(() => {
        window.location.replace(`https://${process.env.REACT_APP_VVIP_DOMAIN}`);
      });
    } else if (value === "add") {
      questionsList.addQuestion(
        new QuestionModel({
          question: "",
          correct_answer: "",
          distractor_1: "",
          distractor_2: "",
          explanation: "",
          question_background: "",
          habit: undefined,
          language: "en",
          evaluated: false,
          editable: true,
          newQuestion: true,
        })
      );
    }
  };

  const removeQuestion = (qIndex: number) => {
    questionsList.removeQuestion(qIndex);
    if (questionsList.questions.length === 0 && ready) {
      setOpenNoQuestionsDialog(true);
    }
  };

  const generateKey = (excludedElement: Tag[]): number => {
    let randomNumber;
    const pathIds = excludedElement.map((path) => path.key);
    do {
      randomNumber = Math.floor(Math.random() * (100 - 0 + 1)) + 0;
    } while (pathIds.includes(randomNumber));
    return randomNumber;
  };

  const addpaths = (tag: string) => {
    const newpaths: Tag[] = [];
    if (tag === "") {
      return;
    }
    if (tag.includes(",")) {
      if (paths.length + tag.split(",").length <= 8) {
        for (let i = 0; i < tag.split(",").length; i++) {
          newpaths.push({
            key: generateKey([...paths, ...newpaths]),
            label: tag.split(",")[i].trim(),
          });
        }
      }
    } else {
      if (paths.length + newpaths.length >= 8) {
        return;
      }
      newpaths.push({ key: generateKey([...paths, ...newpaths]), label: tag });
    }
    setPaths((current) => [...current, ...newpaths]);
    handlePathsOperations([...paths, ...newpaths]);
    setAutocompleteValue("");
  };

  const deleteTag = (tagToDelete: Tag) => {
    setPaths((paths: Tag[]) =>
      paths.filter((paths: Tag) => paths.key !== tagToDelete.key)
    );
    handlePathsOperations(
      paths.filter((paths: Tag) => paths.key !== tagToDelete.key)
    );
  };

  const handlePathsOperations = (paths: Tag[]) => {
    put("/paths", { paths: paths.map((path) => path.label) })
      .then(() => {})
      .catch(() => {
        toast.error(t("questions.error-save-paths"));
      });
  };

  function reorderPaths(result: any) {
    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const tags = [...paths];
    const [removed] = tags.splice(startIndex, 1);
    tags.splice(endIndex, 0, removed);

    setPaths(tags);
    handlePathsOperations(tags);
  }

  useEffect(() => {
    if (paths && paths.length) {
      setPathError(paths.length !== 8);
    }
  }, [paths]);

  return (
    <>
      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleConfirmationDialogClose}
        allEvaluated={evaluatedQuestions === questionsList.questions.length}
        evaluatedQuestions={evaluatedQuestions}
        paths={paths}
      />
      <NoQuestionsDialog
        open={openNoQuestionsDialog}
        onClose={handleNoQuestionsDialogClose}
      />
      <Grid container spacing={{xs: 3, lg: 0, xl: 0}} px={{xs: 3, lg: 0, xl: 0}}>
        <Grid item xs={4} xl={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              position: "fixed",
              mt: 4,
              width: "350px",
              zIndex: 1000,
            }}
          >
            <Card sx={{ borderRadius: "30px" }}>
              <CardContent>
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("questions.saving-questions-paths")}:
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  {t("questions.chapter-limit")}
                </Typography>
                {paths && paths.length ? (
                  <Grid item sx={{ mt: 2, mb: 4 }}>
                    <DragDropContext onDragEnd={reorderPaths}>
                      <Droppable droppableId="droppable">
                        {(provided: any) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {paths.map((tag: Tag, index: number) => (
                              <Draggable
                                key={tag.key}
                                draggableId={tag.key.toString()}
                                index={index}
                              >
                                {(provided: any, snapshot: any) => (
                                  <Chip
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    variant={
                                      snapshot.isDragging
                                        ? "elevation"
                                        : "outlined"
                                    }
                                    elevation={4}
                                    key={tag.key}
                                    sx={{
                                      mb: 0.5,
                                      display: "flex",
                                      width: "fit-content",
                                      p: 0.5,
                                      height: "auto",
                                      "& .MuiChip-label": {
                                        display: "block",
                                        whiteSpace: "normal",
                                        wordBreak: "break-all"
                                      },
                                    }}
                                    label={tag.label}
                                    onDelete={() => deleteTag(tag)}
                                    icon={
                                      <>
                                        <DragIndicatorIcon />
                                        <Avatar
                                          sizes="small"
                                          sx={{
                                            width: 18,
                                            height: 18,
                                            background:
                                              "linear-gradient(to right, #D74138, #EAAE2B)",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#FFFFFF",
                                              fontSize: "0.75rem",
                                            }}
                                          >
                                            {index + 1}
                                          </Typography>
                                        </Avatar>
                                      </>
                                    }
                                  />
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>
                ) : null}
                {paths.length === 8 ? null : (
                  <InputPath
                    pathError={pathError}
                    saveError={saveErrorPath}
                    autocompleteValue={autocompleteValue}
                    onChange={(value) => {
                      if (value) {
                        setAutocompleteValue(value);
                      }
                    }}
                    addpaths={addpaths}
                  />
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={4} xl={6}>
          {questionsList.questions.map((q, qIndex) => (
            <QuestionCard
              key={"question-card-" + qIndex}
              q={q}
              qIndex={qIndex}
              saveError={saveErrorHabit}
              onSetEvaluatedQuestions={(value) =>
                setEvaluatedQuestions(evaluatedQuestions + value)
              }
              onRemoveQuestion={() => removeQuestion(qIndex)}
              onSetNextHabit={() => {
                if (q.habit) {
                  questionsList.questions.map((qu, i) => {
                    if (i >= qIndex) {
                      if (!qu.evaluated) {
                        qu.setHabit(q.habit);
                      }
                      return qu;
                    } else {
                      return qu;
                    }
                  });
                }
              }}
              paths={paths}
            />
          ))}
          {questionsList.questions && questionsList.questions.length ? (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Button
                className="saveButton"
                variant="contained"
                disabled={isSaving}
                sx={{
                  background: !isSaving
                    ? "linear-gradient(to right, #D74138, #EAAE2B)"
                    : "#C7C7C7",
                  backgroundColor: !isSaving ? "auto" : "none",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  mb: 3,
                }}
                onClick={() => {
                  if (questionsList.percentage.percentageError) {
                    setSaveErrorPercentage(true);
                    setTimeout(() => {
                      setSaveErrorPercentage(false);
                    }, 3000);
                  }
                  if (evaluatedQuestions < questionsList.questions.length) {
                    setSaveErrorHabit(true);
                    setTimeout(() => {
                      setSaveErrorHabit(false);
                    }, 3000);
                  }
                  if (paths.length !== 8) {
                    setSaveErrorPath(true);
                    setTimeout(() => {
                      setSaveErrorPath(false);
                    }, 3000);
                  }
                  if (
                    !questionsList.percentage.percentageError &&
                    evaluatedQuestions === questionsList.questions.length &&
                    paths.length === 8
                  ) {
                    setOpenConfirmationDialog(true);
                  }
                }}
              >
                {t("questions.send")}
              </Button>
            </Box>
          ) : null}
        </Grid>

        <Grid item xs={4} xl={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              position: "fixed",
              mt: 4,
              width: "350px",
              zIndex: 1000,
            }}
          >
            <Card sx={{ borderRadius: "30px" }}>
              <CardContent>
                <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                  {t("questions.generated-questions")}: {evaluatedQuestions}/
                  {questionsList.questions.length}
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  {t("questions.generator-file-name")}: {fileName}
                </Typography>
                <Grid container>
                  <Grid item xs={10}>
                    <PercentageInput
                      key="percentage"
                      model={questionsList.percentage}
                      saveError={saveErrorPercentage}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title={t("questions.percentage-helper-text")}>
                      <InfoIcon
                        style={{
                          color: "#3E4C58",
                          marginTop: "0.5rem",
                          display: "block",
                          marginLeft: "0.75rem",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  sx={{
                    background: "linear-gradient(to right, #D74138, #EAAE2B)",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px",
                    mt: 2,
                  }}
                  onClick={() => {
                    questionsList.addQuestion(
                      new QuestionModel({
                        question: "",
                        correct_answer: "",
                        distractor_1: "",
                        distractor_2: "",
                        explanation: "",
                        question_background: "",
                        habit: undefined,
                        language: "en",
                        evaluated: false,
                        editable: true,
                        newQuestion: true,
                      })
                    );
                    setTimeout(() => {
                      window.scrollTo({
                        left: 0,
                        top: document.body.scrollHeight,
                        behavior: "smooth",
                      });
                    }, 100);
                  }}
                >
                  {t("questions.add-question")}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    background: "linear-gradient(to right, #D74138, #EAAE2B)",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px",
                    mt: 2,
                  }}
                  onClick={() =>
                    pauseSession(
                      questionsList.questions,
                      questionsList.percentage.value
                    )
                  }
                >
                  {t("questions.pause-and-resume")}
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default observer(Questions);
