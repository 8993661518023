import {
  AppBar,
  Box,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../services/AuthService/AuthService";

function Header() {
  const { t } = useTranslation();
  const auth = useAuth();

  // top-right logged user name and/or email
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");

  useEffect(() => {
    const token = auth.getToken();
    if (token) {
      const decoded = jwtDecode(token);
      setEmail((decoded as any).email);
      setName((decoded as any).nickname);
    }
  }, []);

  const selectDenomination = () => {
    if (name && !name.includes("undefined")) {
      return name;
    }
    if (email && !email.includes("undefined")) {
      return email;
    }
    return "";
  };

  return (
    <AppBar
      sx={{
        backgroundColor: "#FFFFFF",
      }}
    >
      <Container maxWidth={false} sx={{ maxWidth: "100%" }}>
        <Toolbar disableGutters>
          <Grid container>
            <Grid item xs={4}>
              <Box></Box>
            </Grid>

            <Grid item xs={4} sx={{ textAlign: "center" }}>
              <Box
                component="img"
                sx={{
                  height: 47.5,
                  cursor: "pointer",
                }}
                alt={t("alt.logo")}
                src="../../../logo.jpeg"
                onClick={() =>
                  window.location.replace(
                    `https://${process.env.REACT_APP_VVIP_DOMAIN}`
                  )
                }
              />
            </Grid>

            <Grid item xs={4} sx={{ alignSelf: "center", textAlign: "right" }}>
              <Typography>{selectDenomination()}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
