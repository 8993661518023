import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { get } from "../../services/ApiFunctions";
import storageType from "../../services/storageService";
import { UserStatus } from "../../types/UserStatus";
import { UserStatusResponse } from "../../types/UserStatusResponse";
import Error from "./Error";
import InsertCoupon from "./InsertCoupon";
import PendingCoupon from "./PendingCoupon";

function Coupon() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userStatus, setUserStatus] = useState<string>("");

  const getUserStatus = () => {
    // if token is exipired -> ResponseInterceptor handle this error
    get<UserStatusResponse>("/user-status")
      .then((res) => {
        if (
          res.data.status === UserStatus.READY &&
          !storageType(`${process.env.REACT_APP_STORAGE}`).getItem("status")
        ) {
          setUserStatus(UserStatus.READY);
          navigate(`/coupon?status=${UserStatus.READY}`);
        } else if (
          res.data.status === UserStatus.GENERATING ||
          (res.data.status.split("-")[0] !== UserStatus.EMAIL_SENT &&
            res.data.status.split("-")[0] !== UserStatus.PAUSED &&
            storageType(`${process.env.REACT_APP_STORAGE}`).getItem("status"))
        ) {
          setUserStatus(UserStatus.GENERATING);
          navigate(`/coupon?status=${UserStatus.GENERATING}`);
        } else if (
          res.data.status.split("-")[0] === UserStatus.EMAIL_SENT ||
          res.data.status.split("-")[0] === UserStatus.PAUSED
        ) {
          storageType(`${process.env.REACT_APP_STORAGE}`).removeItem("status");
          let question_id =
            searchParams.get("question_id") ||
            res.data.status.split("-")[1] ||
            null;
          if (question_id) {
            storageType(`${process.env.REACT_APP_STORAGE}`).setItem(
              "question_id",
              question_id
            );
          } else if (
            storageType(`${process.env.REACT_APP_STORAGE}`).getItem(
              "question_id"
            )
          ) {
            question_id = storageType(
              `${process.env.REACT_APP_STORAGE}`
            ).getItem("question_id");
          }
          if (question_id) {
            navigate(`/questions?id=${question_id}`);
          } else {
            navigate(`/coupon?status=${UserStatus.ERROR}`);
          }
        }
      })
      .catch(() => toast.error(t("coupon.error-user-status")));
  };

  useEffect(() => {
    const statusQueryParam = searchParams.get("status");
    if (!statusQueryParam) {
      getUserStatus();
    } else if (statusQueryParam === "READY") {
      setUserStatus(UserStatus.READY);
    } else if (statusQueryParam === "GENERATING") {
      setUserStatus(UserStatus.GENERATING);
    } else if (statusQueryParam === "ERROR") {
      setUserStatus(UserStatus.ERROR);
    }
  }, []);

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item xs={4}></Grid>
      <Grid
        item
        xs={4}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {userStatus === UserStatus.READY && <InsertCoupon />}
        {userStatus === UserStatus.GENERATING && <PendingCoupon />}
        {userStatus === UserStatus.ERROR && <Error type="question" />}
        {userStatus !== UserStatus.READY &&
          userStatus !== UserStatus.GENERATING &&
          userStatus !== UserStatus.ERROR && <Error type="coupon" />}
      </Grid>
      <Grid item xs={4}></Grid>
      {UserStatus.READY && (
        <Box sx={{ position: "fixed", bottom: 0, right: 0, p: 4 }}>
          <Button
            variant="contained"
            sx={{
              background: "linear-gradient(to right, #D74138, #EAAE2B)",
              borderTopLeftRadius: "30px",
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px",
            }}
            onClick={() =>
              window.location.replace(
                `https://${process.env.REACT_APP_VVIP_DOMAIN}`
              )
            }
          >
            {t("generating.return-to-vv")}
          </Button>
        </Box>
      )}
    </Grid>
  );
}

export default Coupon;
