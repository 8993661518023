import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Tag } from "../../types/Question";

type ConfirmationDialogProps = {
  open: boolean;
  onClose: (returnValue: boolean) => void;
  allEvaluated: boolean;
  evaluatedQuestions: number;
  paths: Tag[];
};

function ConfirmationDialog({
  open,
  onClose,
  allEvaluated,
  evaluatedQuestions,
  paths
}: ConfirmationDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          borderRadius: "30px",
          p: 4,
          textAlign: "center",
        },
      }}
    >
      <DialogTitle>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12}>
            <Typography>{t("questions.saving-questions-summary")}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ px: 4 }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <Grid item>
            <Typography variant="h5">{t("upload.summary")}</Typography>
          </Grid>
          <Grid item textAlign="left">
            <Typography sx={{ fontWeight: "bold" }}>
              {t("questions.generated-questions")}: {evaluatedQuestions}
            </Typography>
            <Typography sx={{ fontWeight: "bold", my: 1 }}>
              {t("questions.saving-questions-paths")}:
              {paths.map((tag: Tag) => (
                <Chip
                  key={tag.key}
                  sx={{ ml: 2, px: 0.5 }}
                  variant="outlined"
                  label={tag.label}
                />
              ))}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ whiteSpace: "pre-line" }}>
              {allEvaluated ? (
                <Trans
                  style={{ whiteSpace: "pre-line" }}
                  i18nKey="questions.are-you-sure"
                />
              ) : (
                t("questions.check-questions-confirmation")
              )}
            </Typography>
          </Grid>
          <Box sx={{ textAlign: "center" }}>
            {allEvaluated ? (
              <Box sx={{ pt: 2, textAlign: "center" }}>
                <Button
                  variant="contained"
                  disabled={!paths || !paths.length}
                  sx={{
                    background:
                      paths && paths.length
                        ? "linear-gradient(to right, #D74138, #EAAE2B)"
                        : "#C7C7C7",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px",
                    mr: 3,
                  }}
                  onClick={() => {
                    onClose(false);
                  }}
                >
                  {t("questions.cancel")}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    background: "linear-gradient(to right, #D74138, #EAAE2B)",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px",
                  }}
                  onClick={() => onClose(true)}
                >
                  {t("questions.confirm")}
                </Button>
              </Box>
            ) : (
              <Button
                variant="contained"
                sx={{
                  background: "linear-gradient(to right, #D74138, #EAAE2B)",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                }}
                onClick={() => onClose(false)}
              >
                Ok
              </Button>
            )}
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationDialog;
