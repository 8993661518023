import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // lazy load in memory translations
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./assets/locales/en/translations.json`),
    ),
  )
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    preload: ["en"],
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
